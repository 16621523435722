import { Box, Button, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import Head from 'next/head';
import Link from 'next/link';

const NotFound = () => {
  return (
    <>
      <Head>
        <title>{'Page Not Found'}</title>
      </Head>
      <Stack spacing={0} height="100vh" justify="center">
        <VStack>
          <Box
            width={{ base: '100%', md: '70%', lg: '50%' }}
            margin="0 auto"
            mt={{ base: 20, md: 8, lg: 4 }}></Box>
          <VStack textAlign="center">
            <Heading
              textTransform="capitalize"
              textAlign="center"
              fontSize={{ base: '2xl', md: '3xl', lg: '5xl' }}>
              404
            </Heading>
            <Text fontSize={{ base: 'xl' }} maxWidth={{ base: '75%', md: '100%', lg: '100%' }}>
              Sorry, we were unable to find that page
            </Text>
            <Link href="/" passHref>
              <Button colorScheme="cottage_green">Back to Home</Button>
            </Link>
          </VStack>
        </VStack>
      </Stack>
    </>
  );
};

export default NotFound;
